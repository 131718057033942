/* Since some of the app is not yet in CommonJS, we can export the modules
 * we'll need in the rest of the app onto window here:
 */

var globalModules = {
  "MainContainer":      require("./components/MainContainer.jsx"),
};

Object.keys(globalModules).forEach(
  key => window[key] = globalModules[key]
);